'use client'

import { createTheme } from '@mui/material/styles'

// Types
import type { Direction } from '@mui/material/styles'

// Fonts
import AxiformaFont from '@/styles/fonts/axiforma'
import PeydaFont from '@/styles/fonts/peyda'

const ThemeConfig = (dir: Direction) =>
	createTheme({
		direction: dir,
		cssVariables: true,
		palette: {
			mode: 'dark',
			primary: { light: '#ffc1c1', main: '#ff8484', dark: '#ff6666', contrastText: '#fff' },
			secondary: { light: '#87d9fc', main: '#10B4F9', dark: '#0b7dae', contrastText: '#fff' },
			text: { primary: '#fff', secondary: '#fff' },
			background: { default: '#070f2b' }
		},
		shape: { borderRadius: 16 },
		typography: {
			fontFamily: dir === 'rtl' ? PeydaFont.style.fontFamily : AxiformaFont.style.fontFamily,
			fontSize: 14
		},
		components: {
			MuiCssBaseline: {
				styleOverrides: {
					body: { scrollBehavior: 'smooth' },
					a: { textDecoration: 'none' }
				}
			}
		}
	})

export default ThemeConfig
